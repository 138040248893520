<template>
  <div class='nowrap'>
    <img class='noimg' :src="require('@/assets/image/nodata.png')" />
    <aside class='notip'>没有搜索到您想要的素材?</aside>
    <aside class='resetsearch'>重新搜索</aside>
    <p class='kf'>客服电话：135-2235-2793（微信同步）</p>
  </div>
</template>

<script>
export default {
  name: 'noData'
}
</script>

<style scoped lang='scss'>
.nowrap{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  .noimg{
    display: inline-block;
    margin-top: 30%;
    border: 1px dashed;
    border-radius: 2px;
  }
  .notip{
    display: inline-block;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0,0,0,0.25);
    line-height: 24px;
    margin-top: 6px;
  }
  .resetsearch{
    width: 96px;
    height: 32px;
    border-radius: 16px;
    border: 1px solid #E52A0D;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #E52A0D;
    line-height: 32px;
    text-align: center;
    margin-top: 12px;
  }
  .kf{
    margin-top: 36px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0,0,0,0.5);
    line-height: 24px;
    text-align: center;
  }
}
</style>
