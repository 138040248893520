<template>
  <!--h5 侧边栏（筛选）-->
  <el-drawer
    size='83%'
    :show-close='false'
    :visible.sync="visibleDraw">
    <div slot='title' class='drawer_title'>
      <span class='title'>{{title}}</span>
      <span class='close_span' @click="cancel">取消</span>
    </div>
    <div class='drawContent'>
      <div class='tag_list'>
        <div class='tab_nav'>
          <div class="content-tag-list">
            <el-collapse>
              <el-collapse-item :title="item.label_name" v-for="(item,idx) in data" :key="idx">
                <p :class="{active: setStyle(o.label_id)}" v-for="(o,i) in item.son" :key="i" @click="getTag(o)">{{o.label_name || o.name}}</p>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
      <div class='draw_foot'>
        <el-button @click="reset">重置</el-button>
        <el-button @click="setTag">确定</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
export default {
  props:{
    title:{
      type: String,
      default: '标签筛选'
    },
    visible:{
      type: Boolean,
      default: false
    },
    data:{
      type: Array,
      default:()=>{
        return []
      }
    },
    type:{
      type: String,
      default: '2'
    }
  },
  data(){
    return{
      visibleDraw: false,
      activList: [],
      storeName: ''
    }
  },
  watch:{
    visible: {
      handler(val){
        this.visibleDraw = val;
      },
      immediate: true
    },
    visibleDraw:{
      handler(val){
        if(val === false){
          this.$parent.visible = false;
        }
      },
      immediate: true
    },
  },
  mounted() {
    this.storeName = this.type == '2' ? 'musicTag' : this.type == '3' ? 'voiceTag' : 'videoTag';
    if(window.sessionStorage.getItem(this.storeName)){
      let arr = window.sessionStorage.getItem(this.storeName);
      this.activList =  JSON.parse(arr) || [];
    }
  },
  methods:{
    getTag(item){
      if(this.activList.length>0){
        if(this.activList.find(o=>o==item.label_id)){
          this.activList.forEach((k,i)=>{
            if(k == item.label_id){
              this.activList.splice(i,1);
            }
          })
        }else{
          this.activList.push(item.label_id);
        }
      }else{
        this.activList.push(item.label_id);
      }
    },
    setStyle(id){
      return this.activList.includes(id);
    },
    cancel(){
      this.$parent.visible = false;
      this.visibleDraw = false;
      this.activList = [];
    },
    reset(){
      // if(window.sessionStorage.getItem(this.storeName)){
      //   let arr = window.sessionStorage.getItem(this.storeName);
      //   this.activList =  JSON.parse(arr) || [];
      // }
      this.activList = [];
      window.sessionStorage.setItem(this.storeName, JSON.stringify(this.activList));
      this.visibleDraw = false;
      this.$parent.visible = false;
      if(this.$parent){
        this.$parent.page = 1;
        this.$parent.getList();
      }
    },
    setTag(){
      if(this.activList.length > 0){
        window.sessionStorage.setItem(this.storeName, JSON.stringify(this.activList));
      }else{
        window.sessionStorage.setItem(this.storeName, "");
      }
      this.visibleDraw = false;
      this.$parent.visible = false;
      if(this.$parent){
        this.$parent.page = 1;
        this.$parent.getList();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.drawer_title{
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .title{
    display: inline-block;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 33px;
  }
  .close_span{
    display: inline-block;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51,51,51,0.5);
    line-height: 20px;
  }
}
.drawContent{
  width: 100%;
  height: 100%;
  padding: 0 23px 20px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  .tag_list{
    flex: 1;
    overflow-y: auto;
  }
  .draw_foot{
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
  }
}

/deep/{
  .el-collapse,.el-collapse-item__header,.el-collapse-item__wrap{
    border: none;
  }
  .el-collapse-item__header{
    height: 17px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51,51,51,0.75);
    line-height: 17px;
  }
  .el-collapse-item{
    margin-top: 24px;
    &:first-child{
      margin-top: 0;
    }
    &:last-child{
      margin-bottom: 24px;
    }
  }
  .el-collapse-item__content{
    padding-bottom: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    p{
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      border: 1px solid #333333;
      text-align: center;
      padding: 0 14px;
      box-sizing: border-box;
      margin-right: 10px;
      margin-top: 10px;
      &:last-child{
        margin-right: 0;
      }
    }
    .active{
      border-color: #E52A0D;
      color: #E52A0D;
    }
  }
  .el-collapse-item__arrow{
    font-size: 14px;
  }
  .el-collapse-item__wrap{
    margin-top: 5px;
  }
  .el-button{
    flex: 1;
    height: 100%;
    background: transparent;
    border-radius: 4px;
    &:first-child{
      margin-right: 6px;
      border: 1px solid #E52A0D;
      color: #E52A0D;
      background: #fff;
    }
    &:last-child{
      margin-left: 6px;
      background: #E52A0D;
      color: #fff;
      border: 1px solid #E52A0D;
    }
  }
}
</style>
