<template>
  <scroller :on-infinite="infinite" :on-refresh="refresh" ref="scroller">
    <slot></slot>
  </scroller>
</template>
<script>
export default {
  methods:{
    //上拉加载
    infinite(){
      this.$emit("loadMore");
      // this.$refs.scroller.finishInfinite(true);
    },
    //下拉刷新
    refresh:function(){
      this.$emit("refresh");
      // this.$refs.scroller.finishPullToRefresh();
    }
  }
}
</script>
<style scoped lang='scss'>
///deep/{
//  ._v-content{
//    height: 100%;
//    display: flex;
//    flex-direction: column;
//    flex-shrink: 0;
//  }
//}
</style>
