<template>
  <header class="head">
    <div class="head_top">
      <aside class='head_logo'>
        <img src="~@/assets/image/mobile/mlogo.png" />
      </aside>
      <div class='head_search' v-if='showSearch'>
        <aside class='search_icon'>
          <img src="~@/assets/image/mobile/search.png" />
        </aside>
        <el-input v-model.trim="search" placeholder='请输入关键词搜索'></el-input>
      </div>
      <div class='head_avatar'>
        <img v-if='userInfo' :src="userInfo.profile_photo_url" />
        <img v-else src="~@/assets/image/default-avatar.png" />
      </div>
    </div>
    <div class="head_tag" v-if='showTag'>
      <div class='tag_list'>
        <el-tag v-for="(item, index) in orderList" :key="index" :class="{active: activIdx===index}" @click="setTag(item,index)">{{ item.label }}</el-tag>
      </div>
      <div class='tag_filter' @click="tagfilter" v-if='showTagFilter'>标签筛选</div>
    </div>
  </header>
</template>
<script>
import { orderList } from '@/config';
import {debounce as _debounce} from '@/utils/underscore';
import { mapGetters } from 'vuex'
export default {
  props:{
    showTag:{
      type: Boolean,
      default: true
    },
    showTagFilter:{
      type: Boolean,
      default: true
    },
    showSearch: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return{
      orderList,
      activIdx: 0,
      search: ''
    }
  },
  computed:{
    ...mapGetters(['userInfo']),
  },
  watch:{
    search:{
      handler(val){
        val && this.setSearch();
      },
      immediate: false
    }
  },
  methods:{
    setSearch: _debounce(function(){
      this.$emit('search', this.search);
    },300),
    setTag(item,index){
      this.$emit('getId', item);
      this.activIdx = index;
    },
    tagfilter(){
      this.$emit('visible', true);
    }
  }
}
</script>
<style lang="scss" scoped>
.head{
  width: 100%;
  //height: 80px;
  background: #fff;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 7px 20px 8px 20px;
  box-sizing: border-box;
  .head_top{
    width: 100%;
    //height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head_logo{
      height: 12px;
      margin-right: 12px;
      img{
        display: inline-block;
        height: 100%;
      }
    }
    .head_search{
      flex: 1;
      height: 30px;
      display: flex;
      align-items: center;
      background: #F7F7F7;
      border-radius: 14px;
      padding: 7px 17px 7px 17px;
      box-sizing: border-box;
      .search_icon{
        display: inline-block;
        width: 14px;
        height: 14px;
        img{
          display: inline-block;
          height: 100%;
        }
      }
    }
    .head_avatar{
      width: 30px;
      height: 30px;
      margin-left: 12px;
      img{
        display: inline-block;
        height: 100%;
      }
    }
  }
  .head_tag{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    .active{
      background: rgba(255,47,15,0.05);
      color: #E52A0D;
    }
    .tag_filter{
      width: 60px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid rgba(51,51,51,0.3);
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(51,51,51,0.75);
    }
  }
}
/deep/{
  .el-input__inner{
    border: none;
    background: transparent;
    height: 100%;
    padding: 0;
    margin-left: 8px;
  }
  .el-tag{
    width: 40px;
    height: 20px;
    line-height: 20px;
    background: rgba(51,51,51,0.05);
    border-radius: 4px;
    padding: 0 8px;
    box-sizing: border-box;
    text-align: center;
    margin-right: 8px;
    border: none;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51,51,51,0.75);
  }
}
</style>
